@font-face {
  font-family: barMenuFontRegular;
  src: url(./Lora-VariableFont_wght.ttf);
}

@font-face {
  font-family: sectionHeader;
  src: url(./FrederickatheGreat-Regular.ttf);
}

@font-face {
  font-family: season;
  src: url(./AndadaPro-VariableFont_wght.ttf);
}

.title {
  display: flex;
  justify-content: center;
}

.season-box {
  display: flex;
  justify-content: flex-end;
  margin-left: 24;
  margin-right: 24;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.season {
  font-family: season;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  /* or 257% */

  text-align: end;
  letter-spacing: 0.5em;
}

.section-row-col {
  padding-left: 0;
  display: flex;
}

.section-head {
  font-family: sectionHeader;
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 50px;
  max-width: 50%;
  margin-bottom: 25px;
}

.section-line-box {
  display: flex;
  align-self: flex-end;
}

.section-line {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  height: 0%;
  width: 100%;
  align-self: baseline;
  margin-bottom: 30px;
  padding-bottom: 25px;
}

.name {
  font-family: barMenuFontRegular;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 25.6px;
  padding: 0px;
  padding-left: 5px;
  gap: 12px;
}

.ingredients-text {
  font-family: barMenuFontRegular;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.36px;
  padding-top: 8px;
}

.quip {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 3px;
  display: flex;
}

.quip-text {
  font-family: barMenuFontRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 0;
}

.menu {
  padding-right: 25px;
  padding-top: 30px;
  border: 8px solid;
}

.section {
  padding-left: 3px !important;
  padding-top: 10px;
}

.drink {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 10px;
  padding-bottom: 5px;
}

.drink-pic {
  max-height: 2em;
  padding-bottom: 5px;
}

body {
  background-color: #FFFFFF !important;
}
