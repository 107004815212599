.wrapper-div {
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 15px;
  margin-top: 10px;
  padding: 15px;
}

.suggestion-text-div {
  display: block !important;
}

.suggestion-text {
  padding-left: 15px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.suggestion-header {
  padding-right: 0px !important;
}

.rating-width {
  width: 75px;
}

.photo-div {
  display: grid;
  align-items: end;
}

.details-table-div {
  display: flex;
  align-items: center;
}

.similar-button {
  height: 100%;
}

img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 200px;
}
