.distance-number {
  background: white;
  border-style: ridge;
  border-radius: 12px;
  border-color: lightblue;
  /*margin-left: 20px;*/
  padding: 3px;
  width: 65px;
  text-align: right;
}

.distance-slider-col {
  align-items: center;
  display: flex;
}

.distance-number-col {
  padding-left: 0px !important;
}

.find-place-button {
  background-color: white;
  border-style: solid;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  width: 175px;
  margin: 5px;
}

.address-input {
  width: 100%;
  padding: 5px;
  border-radius: 7px;
  border-color: lightblue;
}
