.flexbox-div {
  display: flex;
}

.centered {
  align-self: center;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.section-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.visible-content-div {
  min-width: 200px;
  max-width: 400px;
  width: 80%;
}

p {
  padding-left: 10px;
  padding-right: 10px;
}
